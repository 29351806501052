<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.4 77.4" width="35" height="57.4" xml:space="preserve" :fill="fill">
    <path d="M26.7 0v73.6L50 50.3l1.4 1.4-25.7 25.7L0 51.7l1.4-1.4 23.3 23.3V0h2z" fill-rule="evenodd" clip-rule="evenodd"/>
  </svg>
</template>

<script>
  export default {
    name: 'ArrowSVG',
    props: {
      fill: {
        type: String,
        default: '#F3DFDB'
      }
    }
  }
  </script>