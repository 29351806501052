import Parallax from './parallax'
import Reveal from './reveal'
import Loader from './loader'
import Cursor from './cursor';

document.addEventListener(
    'DOMContentLoaded',
    () => {
      new Parallax();
      new Reveal();
      new Loader();
      new Cursor();
    }
);
