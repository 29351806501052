export default class Cursor {
  constructor() {
    this.init();
  }

  init() {
    const cursorSmall = document.querySelector('.cursor');

    // const offset = 40
    const cursor = document.querySelector('[data-button-arrow]')
  
    const positionElement = (e)=> {
      const mouseY = e.clientY;
      const mouseX = e.clientX;
      
      cursorSmall.style.left = `${mouseX}px`;
      cursorSmall.style.top = `${mouseY}px`;

      if (cursor) {
        let x = (cursor.getBoundingClientRect().left) + (cursor.clientWidth / 2);
        let y = (cursor.getBoundingClientRect().top + window.scrollY) + (cursor.clientHeight / 2);
        let radian = Math.atan2(e.pageX - x, e.pageY - y);
        let rot = (radian * (180 / Math.PI) * -1) + 270;
        
        cursor.style.transform = `translate(-50%, -50%) rotate(${rot + 85}deg)`
      }

      
    
      e.target.nodeName === 'A' ? cursorSmall.classList.add('active') : cursorSmall.classList.remove('active')
    }
  
    window.addEventListener('mousemove', positionElement)

  }
}