import rallax from 'rallax.js'

export default class Parallax {
  constructor() {
    this.init()
  }

  init() {
    const element = document.querySelectorAll('[data-banner-introduce]')

    element.forEach((elem) => {
      const options = { speed: 0.1 }
      // eslint-disable-next-line
      const parallax = rallax(elem, options)
    })
  }
}