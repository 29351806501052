<template>
  <div class="c-desc">
    <div class="reveal">
      <Title :title="title" />
    </div>

    <div class="c-desc__content">
      <p class="c-desc__content__text reveal">
        Je suis Claudine Stepien aka Nine, directrice artistique basée à Lyon. 
      </p>
      <p class="c-desc__content__text reveal">
        Passionnée des arts visuels et du travail bien fait, je suis une designer pluridisciplinaire avec une prédisposition pour les expériences digitales et les identités visuelles. Grâce à mes 10 années dans le design graphique, j'ai travaillé pour des clients et entreprises varié·es. J’aime les collaborations qui me font progresser en tant que designer et en tant que personne. 
      </p>
    </div>

    <a href="/static/claudine-stepien-cv-2023.pdf" class="c-desc__btn">
      Mon CV
    </a>

    <div class="c-desc__svg">
      <Meet />
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Title from '../Title.vue';
import Meet from '@/components/graphics/Meet.vue';
import Button from '../Button.vue';

export default {
    name: "Description",
    components: {
    Title,
    Meet,
    Button
},
    data() {
      return {
        title: "fresh artistic director of lyon"
      }
    }
}

</script>