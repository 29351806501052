<template> 
  <div class="c-home">
    <div class="c-home__banner">
      <Banner />
      <div class="c-home__arrow">
        <Arrow />
      </div>
    </div>
    <div class="c-home__wrapper">
      <div class="c-home__container">
        <Description />
      </div>
      <div class="c-home__container">
        <Skills />
      </div>

      <div class="c-home__container">
        <References />
      </div>
    </div>
    <div class="c-home__mentions">
      © Claudine Stepien 2022 - <router-link to="/mentions-legales">Mentions légales</router-link>
    </div>

    <div class="c-home__button">
      <Button />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Logo from '@/components/graphics/Logo.vue';
import Banner from '@/components/template-parts/Banner.vue';
import Description from '@/components/template-parts/Description.vue';
import Button from '../Button.vue';
import References from '../template-parts/References.vue';
import Skills from '../template-parts/Skills.vue';
import Arrow from '../graphics/Arrow.vue';

export default {
  name: 'Home',
  components: {
    Logo,
    Banner,
    Description,
    Button,
    Skills,
    References,
    Arrow
}
}
</script>