<template>
  <div class="c-banner">
    <div class="c-banner__title">
      <span class="c-banner__title--split">Design</span>
      <span class="c-banner__title--split">Branding</span>
      <span class="c-banner__title--split">Digital</span>
    </div>
    <div class="c-banner__img">
      <div class="c-banner__img--hand">
        <Hand />
      </div>

      <div class="c-banner__img--introduce">
        <Introduce />
      </div>
    </div>
  </div>
</template>

<script>
import Hand from '../graphics/Hand.vue';
import Introduce from '../graphics/Introduce.vue';
/* eslint-disable */

export default {
    name: "Banner",
    components: { Hand, Introduce}
}

</script>