<template>
  <div>
    <h2 class="c-title">
      {{ title }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'TitleH2',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>