export default class Reveal {
  constructor() {
    this.revealBlock()
      this.revealNavbar()
  }

  revealFunc(elements) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-revealed')
        }
      })
    }, { threshold: 0.75 })
    

    const targets = document.querySelectorAll(elements)
    targets.forEach(function (target) {
      observer.observe(target)
    })
  }

  revealBlock() {
    this.revealFunc('.reveal')
  }

  revealNavbar() {
    let self = this
    setTimeout(function() {
      self.revealFunc('.c-navbar')
      self.revealFunc('.c-banner')
    }, 2500);
  }
}