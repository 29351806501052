<template>
  <div class="c-skills">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" xml:space="preserve" fill="#ADD8DD" class="c-skills__svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M60 25H42.1l14.1-14.1-7.1-7.1L35 17.9V0H25v17.9L10.9 3.8l-7.1 7.1L17.9 25H0v10h17.9L3.8 49.1l7.1 7.1L25 42.1V60h10V42.1l14.1 14.1 7.1-7.1L42.1 35H60z"/>
    </svg>
    <h2 class="c-skills__title reveal">
      Skills
    </h2>
    <ul class="c-skills__list">
      <li class="c-skills__list__item reveal">
        Direction artistique
      </li>
      <li class="c-skills__list__item reveal">
        Design d’interface web & mobile (Responsive design & UI style guide)
      </li>
      <li class="c-skills__list__item reveal">
        Identité visuelle (Logo & charte graphique)
      </li>
      <li class="c-skills__list__item reveal">
        Illustration & iconographie
      </li>
      <li class="c-skills__list__item reveal">
        Supports de communication web & print
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "Skills"
}

</script>