<template>
  <div>
    <a href="mailto:hello@claudine-stepien.com" class="c-button">
      <svg viewBox="43 20 80 80" class="c-button__svg">
        <defs>
          <path id="circle" d="M 60, 60
              m -37, 0
              a 37,37 0 1,1 120,0
              a 37,37 0 1,1 -120,0"></path>
        </defs>
        <text dy="35" textLength="350" fill="#EAF4F6">
          <textPath xlink:href="#circle" font-family="Space Grotesk Bold">
            COME SAY HELLO•
          </textPath>
        </text>
      </svg>
      <div class="c-button__arrow" data-button-arrow>
        <Arrow />
      </div>
    </a>
  </div>
</template>

<script>
/* eslint-disable */

import Arrow from './graphics/Arrow.vue';

export default {
    name: "Button",
    components: { Arrow }
}

</script>