<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.8 18.7" xml:space="preserve" :fill="fill">
    <path d="M16.8 8.8c0 .1 0 .1 0 0zM14 10.4zm.8.4c.7.1.6-.2.9-2.4.1-.6.6-.2 1-.1 0 0 .5 1.3.6 1.7.2.6.8.4 1-.1-.5-4.8-1.4-6-2.8-6.2-1.6-.3-1 2.1-1.3 5.6v.3c-.6.2-2.5.5-2.5.5-.4-.2-.4-1.6-.4-1.6 0-.1.1-.2.1-.2s2-.7 2.1-.8c.1-.1-.2-.5-.2-.4-.3 0-2.3.4-2.3.4-.2-.4 0-1.7.1-2 0-.2 2.1-.6 1.9-.6.5-.1.2-.4.1-.4-.2-.3-2.7.3-3.4.5 0 0-.3.1-.3.3 0 0 0 5.7.7 6 .4.1 3-.5 3.9-.9.2.2.4.3.8.4zm.9-4.2.2-.6s.4.7.5 1.6c-.6-.1-.8-.3-.7-1zm-1.5 3.8zm24.7-2.8c.3-.1 0-1 .3-1.5.1-.2-.4-2.7-.4-2.8 0-.4 2.9-1 2.7-1.4-.1-.2-.1-.6-.4-.6-1.4-.1-4.8 1.1-4.8 1.7 0 .2.7.2 1 .6 0 0 .3 4.6 1.6 4zM33.7 10c-1.4-.1-4.8 1.1-4.8 1.7 0 .2.7.2 1 .6.1.1.4 4.7 1.7 4.1.3-.1 0-1 .3-1.5.1-.2-.4-2.7-.4-2.8 0-.4 2.9-1 2.7-1.4-.2-.3-.3-.7-.5-.7zm-7-6.3c1.4 0 .8 1.8 1.1 4.3 0 .3-.7 1-.7 1-.1.1.1.3.1.3 1.7-.1 5.2-.9 5-3.7-.2-2.3-2.7-3-4-2.9-.9.1-3.1.9-1.5 1zM30 4c1.6 1.2 1.4 1.9.9 2.6-.5.6-1.9 1.1-1.9 1.1-.2-1.1-.3-3.2-.4-3.7-.2-.8 1.2-.2 1.4 0zM9.2 10.7c-.3-.1-.8 0-.8 0 .1-.1-.6-4.9-.6-5.2.1-.8-.8-.5-1-.3-.3.2-.3 2.7-.2 4 .1 1.7.6 2.8 2.6 2.2.4.1.2-.6 0-.7zM6 6.5c-1-1.1-2.8-1-4.1-.6-.4.2-1.5.7-1.8 1-.2.2.2 1 .8 1.2.4.1.4 2.4.4 2.8.5 2.5 1.1 0 .9-.2 0-.5-.1-1 .1-1.1 0 0 3.2-2 3.4-2.3.1.1.8-.5.3-.8zm-1.4.9c-.5.4-1.1.8-1.5 1-.8.5-.8.3-.9 0 0-.2.1-.6.1-.9.2-.4 1.4-.6 1.7-.7.7-.1 1.2.1.6.6z"/>
    <path d="M43.3 6.7c5.3 1.8 4.3-8.2.6-6.5-1-.1-1.6.4-1.9.8-.6.8-.9 4.2 1.3 5.7zm.1-4.1c2.9-5.9 3.5 7.6-.2 2.5-.2-.2-.1-1.6.2-2.5zM45.3 8c-1-.2-1.7.3-2 .6-.6.8-1.2 4.1.8 5.8 5.2 2.4 5.1-7.7 1.2-6.4zm-1.1 4.9c-.2-.4.1-1.7.5-2.5 3.3-5.6 2.7 7.9-.5 2.5zM23 9.7c.4.2 3.9-.8 4.3-1.1.1-.1-.3-.6-.4-.6-.6.2-2.5.5-2.5.5-.4-.2-.4-1.6-.4-1.6 0-.1.1-.2.1-.2s2-.7 2.1-.8c.1-.1-.2-.5-.2-.4-.1 0-2.1.5-2.1.4-.2-.4 0-1.7.1-2 0-.2 2.1-.6 1.9-.6.5-.1.2-.4.1-.4-.2-.2-2.7.3-3.4.5 0 0-.3.1-.3.3 0 0-.1 5.7.7 6zm1.3 6.8c-.6.2-2.5.5-2.5.5-.4-.2-.4-1.6-.4-1.6 0-.1.1-.2.1-.2s2-.7 2.1-.8c.1-.1-.2-.5-.2-.4-.1 0-2.1.5-2.1.4-.2-.4 0-1.7.1-2 0-.2 2.1-.6 1.9-.6.5-.1.2-.4.1-.4-.2-.2-2.7.3-3.4.5 0 0-.3.1-.3.3 0 0 0 5.7.7 6 .4.2 3.9-.8 4.3-1.1.2-.1-.2-.6-.4-.6zm4.9-.8c-.6.2-2.5.5-2.5.5-.4-.2-.4-1.6-.4-1.6 0-.1.1-.2.1-.2s2-.7 2.1-.8c.1-.1-.2-.5-.2-.4-.1 0-2.1.5-2.1.4-.2-.4 0-1.7.1-2 0-.2 2.1-.6 1.9-.6.5-.1.2-.4.1-.4-.2-.2-2.7.3-3.4.5 0 0-.3.1-.3.3 0 0 0 5.7.7 6 .4.2 3.9-.8 4.3-1.1.2-.1-.2-.7-.4-.6z"/>
    <path d="M51.3 12c-.4 1.3-1-1.3-1.1-2-.2-1.3 0-2.4-1.1-2.6-1.4-.2-.4 6.2 1.9 6.4 1.2.1 1.7-.9 1.8-2.8 0-1.1.2-3.2-.4-3.5-2.4-1.2-.7 3-1.1 4.5zm1.4-1.3c0 .1 0 0 0 0zM19.8 6.1c-.5-.3.7-1.6 1.2-1.7.3-.1 1.4 0 .8-.5-.9-1-2.2-.4-2.8.1 0 0-.7.5-.9.7-.2.2-.5.4-.2.9.4.7 2 1.3 2.3 1.7.2.2-.2 1.5-.4 1.7-.2.2-.4.2-.5.4-.5 2.3 4.5-1.1.5-3.3zm-3.5 7.8c-.5 1.3-.4-.7-1.9-.6-1.2.1-1 5.6.8 5.4 1.2-.1.8-.7.6-2.1-.1-1-.2-1.3.8-.6.6.4.9-1.1 1.1.3.1.8-.3 2.6.8 2.4 1-.2 1.1-10.6-2.2-4.8zm24.6-5.1c-.1.3-.4 1.6-.5 1.6-.2.1-1.1-1.9-1.6-1.5-.4.3-.7.4-.3 1 .1.2 1.4 2.5 1.4 2.5-.6 2.8-.4 3.3 0 3.2.7-.2 1.1-.5 1.2-1.6.2-1.7 1.4-3.9 1.4-5.3-.9-.4-1.5-.5-1.6.1z"/>
  </svg>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Meet',
  props: {
    fill: {
      type: String,
      default: "#51CEB9"
    }
  }
}
</script>