export default class Loader {
  constructor() {
    this.init();
  }
  init() {
    const loader = document.querySelector('.loader');

    window.addEventListener('load', () => {
      setTimeout(function() {
        loader.classList.add('hide-loader');
      }, 800);
    });
  }
}
