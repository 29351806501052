<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.9 43.6" xml:space="preserve">
    <g :fill="fill">
      <path d="M41.4 43H28.1V18.8c0-1 0-1.9-.3-2.9-.2-1-.6-1.8-1.2-2.6s-1.3-1.4-2.2-1.9c-.9-.5-2-.7-3.3-.7-2 0-3.8.6-5.3 1.9-1.5 1.3-2.3 3.1-2.3 5.6V43H0V.6h13.5L13.4 8c.4-1.1 1-2.2 1.8-3.2S16.9 3 18 2.3c1.1-.7 2.3-1.2 3.7-1.6 1.4-.5 2.9-.7 4.4-.7 2 0 3.9.3 5.8 1s3.5 1.6 4.9 2.9c1.4 1.3 2.5 2.9 3.4 4.8.8 1.9 1.3 4.2 1.3 6.7V43zm8.2 0V.6h13.5V43H49.6zm64 0h-13.4V18.8c0-1 0-1.9-.3-2.9-.2-1-.6-1.8-1.2-2.6s-1.3-1.4-2.2-1.9c-.9-.5-2-.7-3.3-.7-2 0-3.8.6-5.3 1.9-1.5 1.3-2.3 3.1-2.3 5.6V43H72.2V.6h13.5L85.6 8c.4-1.1 1-2.2 1.8-3.2s1.7-1.8 2.8-2.5c1.1-.7 2.3-1.2 3.7-1.6 1.4-.5 2.9-.7 4.4-.7 2 0 3.9.3 5.8 1s3.5 1.6 4.9 2.9c1.4 1.3 2.5 2.9 3.4 4.8.8 1.9 1.3 4.2 1.3 6.7V43zm20.5-19.2c.3 3 1.2 5.3 2.6 6.9 1.5 1.6 3.5 2.4 6 2.4s4.4-.5 5.7-1.6c.7-.5 1.2-1.1 1.6-1.7l9.6 5.2c-1.1 1.8-2.5 3.2-4.1 4.5-1.7 1.3-3.7 2.2-5.9 2.9-2.3.7-4.8 1-7.7 1-3 0-5.7-.5-8.3-1.5-2.6-1-4.9-2.4-6.8-4.3-1.9-1.9-3.5-4.1-4.6-6.8-1.1-2.7-1.7-5.7-1.7-9.1 0-3.3.6-6.3 1.7-8.9 1.1-2.7 2.6-5 4.6-6.9s4.2-3.4 6.8-4.5c2.6-1 5.4-1.6 8.5-1.6 3.6 0 6.7.6 9.3 1.7 2.6 1.1 4.8 2.7 6.6 4.6 1.8 2 3.1 4.3 4 6.9.9 2.7 1.3 5.5 1.3 8.5v2.1h-29.2zm8.7-13.6c-2.1 0-3.9.6-5.2 1.7-1.3 1.1-2.3 2.7-2.9 4.8h16.4c-.6-2-1.5-3.5-2.9-4.7-1.3-1.2-3.1-1.8-5.4-1.8z"/>
      <circle cx="176.2" cy="35.8" r="7.7"/>
    </g>
  </svg>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Logo',
  props: {
    fill: {
      type: String,
      default: "#34b1a7"
    }
  }
}
</script>