<template>
  <div>
    <div class="c-navbar">
      <div>
        <span class="c-navbar__title">
          Claudine Stepien
        </span>
        <span class="c-navbar__subtitle">
          Directrice artistique <br />— UI designer
        </span>
      </div>
      <div class="c-navbar--col">
        <div class="c-navbar__logo"> 
          <Logo />
        </div>
        <div class="c-navbar__socials">
          <Socials />
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
import Logo from './graphics/Logo.vue';
import Socials from './Socials.vue';

export default {
  name: 'Menu',
  components: {
    Logo,
    Socials
  }
}

</script>