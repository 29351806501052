<template>
  <div class="c-wrapper">
    <Menu />
    <div class="loader">
      <img src="/static/claudine-stepien-artistic-director-website-loader.gif" alt="">
    </div>
    <div class="cursor-big"></div>
    <div class="cursor"></div>
  </div>
</template>

<script>
import Menu from './components/Menu.vue';

export default {
    name: "App",
    components: {
    Menu
}
}
</script>

<style lang="scss">
  @import "@/assets/scss/main.scss";
</style>