<template> 
  <div class="c-mentions">
    <div class="c-home__wrapper">
      <Title :title="title" />
      <div class="c-desc">
        <p class="c-desc__content__text">
          Claudine STEPIEN - Entrepreneur individuel
          Micro entrepreneur enregistré sous le numéro 88376801200014
        </p>
        <p class="c-desc__content__text">  
          Informations légales
          Site web : www.claudine-stepien.com
          Adresse : 304 A rue Garibaldi, 69007 Lyon, France
          Téléphone : 04 37 66 50 01
          Email : hello[at]claudine-stepien.com
        </p>
        <p class="c-desc__content__text">
          Numéro de TVA Intracommunautaire : FR41883768012
        </p>
        <p class="c-desc__content__text">
          Hébergeur
          OVH 
          Siège social : 2 rue Kellermann, 59100 Roubaix, France
        </p>
        <p class="c-desc__content__text">
          Le site www.claudine-stepien.com peut contenir des liens vers d’autres sites dont il n’exploite pas le contenu. En aucune manière, Claudine STEPIEN ne peut être tenue responsable du contenu, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes qui ne sont ni vérifiées ni approuvées par elle.
        </p>
        <p class="c-desc__content__text">
          Copyright et propriété intellectuelle L’ensemble des éléments figurant sur le site www.claudine-stepien.com sont protégés par les dispositions du Code de la Propriété Intellectuelle. En conséquence, toute reproduction, collecte et utilisation à des fins commerciales de ceux-ci, totale ou partielle, ainsi que toute imitation, sans l’accord exprès, préalable et écrit, de Claudine STEPIEN est interdite.
        </p>
        <p class="c-desc__content__text">
          Cette interdiction s’étend notamment à tout élément rédactionnel figurant sur le site, aux logos, images, photos, cette liste n’étant pas limitative.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Title from '@/components/Title.vue';

export default {
  name: 'Mentions',
  components: {
    Title
  },
  data() {
    return {
      title: "mentions légales"
    }
  }
}
</script>