import { createWebHistory, createRouter } from "vue-router";
import Home from '@/components/views/Home.vue';
import Mentions from '@/components/views/Mentions.vue';

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/mentions-legales/",
        name: "Mentions Légales",
        component: Mentions
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;