<template>
  <ul class="c-socials">
    <li class="c-socials__item">
      <a href="https://www.behance.net/claudinestepien" target="_blank" class="c-socials__item__link">Portfolio</a>
    </li>
    <li class="c-socials__item">
      <a href="https://www.linkedin.com/in/claudine-stepien/" target="_blank" class="c-socials__item__link">Linkedin</a>
    </li>
    <li class="c-socials__item">
      <a href="https://www.behance.net/claudinestepien" target="_blank" class="c-socials__item__link">Dribbble</a>
    </li>
  </ul>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Socials'
}
</script>