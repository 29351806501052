<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 47.8 32.1" xml:space="preserve" :fill="fill">
    <path d="M3.5 11.7c.4 0 .1-.7-.1-.8-.3 0-.8.1-.8.1 0-.1-1.3-4.8-1.3-5 0-.8-.8-.4-1.1-.1-.2.2.2 2.7.4 4 .3 1.6 1 2.6 2.9 1.8zm1-5.9c0-.2 1.9-.9 1.8-.8.4-.2.1-.4.1-.4-.3-.2-2.6.7-3.3 1 0 0-.3.1-.2.3 0 0 .9 5.7 1.6 5.8.4.1 3.8-1.4 4-1.7.1-.3-.4-.7-.5-.6-.6.3-2.4.9-2.4.9-.4-.2-.7-1.5-.7-1.5 0-.1 0-.2.1-.2 0 0 1.9-.9 2-1.1 0-.2-.3-.5-.3-.4-.1 0-2 .8-2 .7-.3-.4-.2-1.7-.2-2zm8-1.6c-.2-.2-.2-.6-.5-.6-1.4 0-4.7 1.4-4.7 1.9 0 .2.7.1 1 .5.1.1.7 4.6 1.9 4 .3-.2 0-1 .2-1.5.1-.2-.6-2.7-.6-2.8.1-.3 2.9-1.1 2.7-1.5zM16.9 3c-1.2.3.2 5.6 1.9 5.1 1.2-.3.6-.9.1-2.2-.3-1-.5-1.2.6-.7.7.3.6-1.3 1.1.1.3.7.2 2.6 1.2 2.2.9-.4-1.1-10.6-3.1-4.2-.1 1.4-.4-.6-1.8-.3zm3-1.9zm.9 5c.1 0 .1 0 0 0 .1 0 0 0 0 0zm2.8-4.7c0-.2 1.9-.9 1.8-.8.4-.2.1-.4.1-.4-.3-.2-2.6.7-3.3 1 0 0-.3.1-.2.3 0 0 .9 5.7 1.6 5.8.4.1 3.8-1.4 4-1.7.2-.2-.3-.6-.5-.6-.6.3-2.4.9-2.4.9-.4-.2-.7-1.5-.7-1.5 0-.1 0-.2.1-.2 0 0 1.9-.9 2-1.1.1-.1-.2-.5-.2-.4-.1 0-2 .8-2 .7-.3-.3-.3-1.7-.3-2zM16 10.9c0-.2 1.9-.9 1.8-.8.4-.2.1-.4.1-.4-.3-.2-2.6.7-3.3 1 0 0-.3.1-.2.3 0 0 .9 5.7 1.6 5.8.4.1 3.8-1.4 4-1.7.1-.2-.4-.6-.5-.5-.6.3-2.4.9-2.4.9-.4-.2-.7-1.5-.7-1.5 0-.1 0-.2.1-.2 0 0 1.9-.9 2-1.1.1-.1-.2-.5-.2-.4-.1 0-2 .8-2 .7-.3-.5-.3-1.8-.3-2.1zM14.9 17c.4 0 .1-.7-.1-.8-.3 0-.8.1-.8.1 0-.1-1.3-4.8-1.3-5 0-.8-.8-.4-1.1-.1-.2.3.1 2.7.4 4 .4 1.5 1.1 2.6 2.9 1.8z"/>
    <path d="M24.1 14.6c-1.2-4.6-2.2-5.7-3.7-5.7-1.6-.1-.6 2.2-.5 5.7 0 .9.1 1.4.9 1.4.7 0 .6-.3.5-2.5 0-.6.6-.3 1-.2l.8 1.6c.3.5.9.2 1-.3zM20 15.7s.1 0 0 0zm.2 0zm.1 0zm1.7-3.1c-.7 0-.8-.1-.8-.8 0-.4.1-.6.1-.6s.5.5.7 1.4zm.5 1.2zm4.4-5.5c-1-.8-2.3-.1-2.8.5l-.8.8c-.2.2-.4.4-.1 1 .5.7 2.1 1.1 2.4 1.4.2.2-.1 1.6-.2 1.7-.2.2-.4.3-.4.5-.3 2.3 4.3-1.7.1-3.4-.5-.2.5-1.6 1-1.8.4-.1 1.4-.2.8-.7zm2-.3c0-.2 1.9-.9 1.8-.8.4-.2.1-.4.1-.4-.3-.2-2.6.7-3.3 1 0 0-.3.1-.2.3 0 0 .9 5.7 1.6 5.8.4.1 3.8-1.4 4-1.7.1-.2-.4-.6-.5-.5-.6.3-2.4.9-2.4.9-.4-.2-.7-1.5-.7-1.5 0-.1 0-.2.1-.2 0 0 1.9-.9 2-1.1.1-.1-.2-.5-.2-.4-.1 0-2 .8-2 .7-.3-.4-.3-1.8-.3-2.1zM15.4 19.7c-.1-.2-.2-.6-.5-.6-1.4 0-4.7 1.4-4.7 1.9 0 .2.7.1 1 .5.1.1.7 4.6 1.9 4 .3-.2 0-1 .2-1.5.1-.2-.6-2.7-.6-2.8.2-.4 3-1.1 2.7-1.5zM6 26c-.3-1-1.1-6.2-2.5-4.9-.8.7.6 2.6.7 3.9.4 2.8 1.6 2.9 1.8 1z"/>
    <path d="M6.1 22.4c.2 5.2.4 5.1 1.3 4.9.6-.2.7-.7.7-2.4 0-.5.2-.7.3-.6.9.8 1 1.9 1.9 1.9.6 0 .7-.3.3-2-.2-.8-.1-4.4-1.4-3.9-.6.2-.7.6-.6 1.3.1.8-.4-.6-.8-.7-1.3-.5-1.7-.2-1.7 1.5zm4.4 1.5zm8.3-2.8c.1-.1.5-1.2.5-1.2 1.1-2.9-4.3-2.2-3.7 1 .1.5-.1.5 0 .9.2.9.5 3.5 1.3 3.3 0 0 1.3.1 1-1.4-.1-.2-.4-1-.1-1.2 0-.1 1-.5 1.1-.5.1 0 1.4 1.3 1.5 1.6 1.8.5 0-2.8-1.6-2.5zm-.7-1.5c-.2.4-.9 2-.9 1.4 0 0 0-1.6.8-2.3.2-.2.3.7.1.9z"/>
    <path d="M22 23.2c5.3 1.8 4.3-8.1.5-6.5-1-.1-1.6.4-1.9.8-.5.8-.8 4.2 1.4 5.7zm-.1-1.6c-.2-.3-.1-1.7.2-2.5 2.9-5.9 3.5 7.6-.2 2.5z"/>
    <path d="M31.1 19c-.2-2.3-2.7-3-4-2.8-1 .1-3.1.9-1.5.9 1.4 0 .8 1.8 1.1 4.3 0 .3-.7 1-.7 1-.1.1.1.3.1.3 1.7-.1 5.2-.9 5-3.7zm-4.4 2.4zm1.3 0zm1.7-1.4c-.5.6-1.8 1.1-1.8 1.1-.2-1.1-.3-3.2-.4-3.7-.2-.8 1.3-.2 1.4 0 1.6 1.2 1.3 1.9.8 2.6zm1.1-.1z"/>
    <path d="M32.8 17.9c-.3-1.2-.2-2.3-1.2-2.3-1.4-.1.2 5.8 2.3 5.8 1.1 0 1.5-1 1.4-2.7-.1-1-.1-3-.7-3.3-2.2-.9-.2 3-.5 4.3-.4 1.3-1.1-1.1-1.3-1.8zm2.4 0zm0 .5c0 .1 0 0 0 0zm.8-.7c.3 3.9 5.5 2 4.3.8-4.4 2.9-2.8-3.7-2.2-4.2.3-.3.4-.2.9.1.3 0 .9-.1 1-.5-1.9-2.1-4.2.8-4 3.8zm1.7-2.5zm4.2-1.8c0-.2 1.9-.9 1.8-.8.4-.2.1-.4.1-.4-.3-.2-2.6.7-3.3 1 0 0-.3.1-.2.3 0 0 .9 5.7 1.6 5.8.4.1 3.8-1.4 4-1.7.1-.2-.4-.6-.5-.5-.6.3-2.4.9-2.4.9-.4-.2-.7-1.5-.7-1.5 0-.1 0-.2.1-.2 0 0 1.9-.9 2-1.1.1-.1-.2-.5-.2-.4-.1 0-2 .8-2 .7-.3-.5-.3-1.8-.3-2.1zm2 13.5c.4 0 .1-.7-.1-.8-.3 0-.8.1-.8.1 0-.1-1.3-4.8-1.3-5 0-.8-.8-.4-1.1-.1-.2.3.1 2.7.4 4 .4 1.6 1.1 2.6 2.9 1.8zm-6.7-4.2c0-.2 1.9-.9 1.8-.8.4-.2.1-.4.1-.4-.3-.2-2.6.7-3.3 1 0 0-.3.1-.2.3 0 0 .9 5.7 1.6 5.8.4.1 3.8-1.4 4-1.7.1-.2-.4-.6-.5-.5-.6.3-2.4.9-2.4.9-.4-.2-.7-1.5-.7-1.5 0-.1 0-.2.1-.2 0 0 1.9-.9 2-1.1.1-.1-.2-.5-.2-.4-.1 0-2 .8-2 .7-.4-.4-.3-1.8-.3-2.1zm-15 4.1c-1.2.3.2 5.6 1.9 5.1 1.2-.3.6-.9.1-2.2-.3-1-.5-1.2.6-.7.7.3.6-1.3 1.1.1.3.7.2 2.6 1.2 2.2.9-.4-1.1-10.6-3.1-4.2 0 1.4-.3-.7-1.8-.3zm3.1-1.9zm.9 5zm9-6.9c-1-.8-2.3-.1-2.8.5l-.8.8c-.2.2-.4.4-.1 1 .5.7 2.1 1.1 2.4 1.4.2.2-.1 1.6-.2 1.7-.2.2-.4.3-.4.5-.3 2.3 4.3-1.7.1-3.4-.5-.2.5-1.6 1-1.8.3-.1 1.4-.2.8-.7zm12.3-2.7c.2-.1 0-.6-.1-.6-1.3-.3-3.4.4-4.1 1.1-.9 1 1.3 6.3 2.2 5.6.1-.1.4-.4.3-.7-.4-.7-.7-1.1-.4-1.4.6-.9 1.9-.8 1.1-1.6-.6-.6-1.6 1.1-2-.7-.4-1.4 2-1.1 3-1.7zm-3.8.2zm.3-.1z"/>
    <path d="M30.8 23.6c-.9-.1-1.5-.2-1.5.4 0 .3-.2 1.6-.3 1.7-.1.1-1.3-1.7-1.8-1.2-.4.4-.6.5-.1 1.1.1.2 1.7 2.2 1.7 2.3-.1 2.8.1 3.3.5 3.1.7-.3 1-.6.9-1.8 0-1.9.8-4.2.6-5.6zm-2 4zM10.9 12.2c-1.2-.9-3-.4-4.2.3-.4.3-1.3 1-1.6 1.4-.2.3.4 1 1 1 .4 0 .9 2.3 1 2.7 1 2.4 1.1-.2.8-.4-.1-.5-.3-.9-.1-1.1 0 0 2.7-2.6 2.9-2.9.2-.1.7-.8.2-1zm-1.2 1.2c-.4.5-.9 1-1.3 1.3-.7.6-.8.4-.9.1-.1-.2-.1-.7 0-.9.1-.4 1.2-.9 1.4-1 .7-.3 1.3-.1.8.5z"/>
  </svg>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Introduce',
  props: {
    fill: {
      type: String,
      default: "#51CEB9"
    }
  }
}
</script>